/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://moccasinidentifier.com"
  }, "The Moccasin Identifier Project"), " (includes educational resources by grade)"), "\n", React.createElement(_components.h3, null, "Websites of Local Indigenous Nations"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wendake.ca",
    title: "https://wendake.ca"
  }, "Huron-Wendat Nation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://mncfn.ca",
    title: "http://mncfn.ca"
  }, "Mississaugas of the Credit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sixnations.ca"
  }, "Six Nations of the Grand River")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.haudenosauneeconfederacy.com"
  }, "Haudenosaunee Confederacy")), "\n")), "\n", React.createElement(_components.h3, null, "Cultural Centres"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ncct.on.ca",
    title: "https://ncct.on.ca"
  }, "Toronto Council Fire Native Cultural Centre")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ncct.on.ca",
    title: "https://ncct.on.ca"
  }, "Native Canadian Centre of Toronto")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://woodlandculturalcentre.ca"
  }, "Woodland Cultural Centre, Brantford")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ojibweculture.ca",
    title: "https://ojibweculture.ca"
  }, "Ojibwe Cultural Foundation, M’Chigeeng First Nation, Manitoulin Island")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://museehuronwendat.ca"
  }, "Musée Huron-Wendat, Wendake, Quebec")), "\n")), "\n", React.createElement(_components.h3, null, "Contemporary Indigenous Perspectives on Treaties"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=aGMIyGtyT7E"
  }, "Alan Corbiere on the Treaty of Niagara and Anishinaabe treaty making")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=DTpFqm_lUNo"
  }, "Haudenosaunee treaty relations and the Two Row Wampum")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://mncfn.ca/about-mncfn/treaty-lands-and-territory"
  }, "Mississaugas of the Credit Treaty Lands and Territories")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=bNaPdoMzcFM"
  }, "Kim Fullerton on Mississaugas of the Credit treaties")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=fikKyME0qEs"
  }, "Mississaugas of the Credit on the Toronto Purchase/Treaty 13")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=ZFXyPTFsII0"
  }, "A Sacred Trust, 2010, by Mississaugas of the Credit")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=SiU5uvGXhxA"
  }, "Rick Hill Sr. on the Dish with One Spoon")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.ontario.ca/page/videos-indigenous-voices-treaties#section-14"
  }, "Doug Williams, Indigenous Voices on Treaties, Government of Ontario")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://data2.archives.ca/e/e448/e011188230%E2%80%9302.pdf"
  }, "Report of the Royal Commission on Aboriginal People, vol. 2, Treaties"))), "\n", React.createElement(_components.h3, null, "Indigenous Peoples, Canadian Settler Colonialism, and Restoring Right Relationship"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.p, null, "Asch, Michael, John Borrows, James Tully, eds. ", React.createElement(_components.em, null, "Resurgence and Reconciliation: Indigenous Settler Relations and Earth Teachings."), " Toronto: University of Toronto Press, 2018."), React.createElement(_components.p, null, "Borrows, John, and Michael Coyle, eds. ", React.createElement(_components.em, null, "The Right Relationship: Reimagining the Implementation of Historical Treaties."), " Toronto: University of Toronto Press, 2017."), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://indigenousfoundations.arts.ubc.ca/home"
  }, "Indigenous Foundations")), React.createElement(_components.p, null, "Manuel, Arthur, and Grand Chief Ronald M. Derrickson. ", React.createElement(_components.em, null, "Unsettling Canada: A National Wake-Up Call."), " Toronto: Between the Lines, 2015."), React.createElement(_components.p, null, "Robinson, Dylan. “Intergenerational Sense, Intergenerational Responsibility.” In ", React.createElement(_components.em, null, "Arts of Engagement: Taking Aesthetic Action in and beyond the Truth and Reconciliation Commission of Canada"), ", edited by Keavy Martin, Dylan Robinson, and David Garneau, 43–66. Waterloo: Wilfrid Laurier Press, 2016."), React.createElement(_components.p, null, "Simpson, Leanne, ed. ", React.createElement(_components.em, null, "Lighting the Eighth Fire: The Liberation, Resurgence, and Protection of Indigenous Nations."), " Winnipeg: Arbeiter Ring, 2008."), React.createElement(_components.p, null, "Truth and Reconciliation Commission of Canada. ", React.createElement(_components.em, null, "Honoring the Truth, Reconciling for the Future: Summary of the Final Report of the Truth and Reconciliation Commission of Canada."), " Ottawa, 2015. ", React.createElement(_components.a, {
    href: "https://ehprnh2mwo3.exactdn.com/wp-",
    title: "https://ehprnh2mwo3.exactdn.com/wp-"
  }, "https://ehprnh2mwo3.exactdn.com/wp-"), " content/uploads/2021/01/Executive_Summary_English_ Web.pdf."), React.createElement(_components.p, null, "–––. ", React.createElement(_components.em, null, "Truth and Reconciliation Commission of Canada: Calls to Action"), ". ", React.createElement(_components.a, {
    href: "https://ehprnh2mwo3.exactdn.com/wp-content/uploads/2021/01/Calls_to_Action_English2.pdf"
  }, "https://ehprnh2mwo3.exactdn.com/wp-content/")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://yellowheadinstitute.org/"
  }, "Yellowhead Institute"))), "\n", React.createElement(_components.h3, null, "International Action"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.un.org/development/desa/indigenouspeoples/about-us.html"
  }, "Indigenous Peoples at the United Nations")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.un.org/esa/socdev/unpfii/documents/DRIPS_en.pdf"
  }, "United Nations Declaration on the Rights of Indigenous Peoples (UNDRIP), 2007"))), "\n", React.createElement(_components.h3, null, "Toronto’s Indigenous History and Indigenous Community"), "\n", React.createElement("div", {
    className: "section"
  }, React.createElement(_components.p, null, "Bolduc, Denise, Mnawaate Gordon-Corbiere, Rebeka Tabobondung, and Brian Wright-McLeod, eds. ", React.createElement(_components.em, null, "Indigenous Toronto: Stories That Carry This Place"), ". Toronto: Coach House, 2021."), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://firststoryblog.wordpress.com/"
  }, "First Story Toronto")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://indigenouslandstewardshipto.files.wordpress.com/2019/09/the_indigenous_environmental_history_of.pdf"
  }, "Johnson, Jon. “The Indigenous Environmental History of Toronto: ‘The Meeting Place.’” ", React.createElement(_components.em, null, "Exploring Hidden Landscapes"), ", Native Canadian Centre")), React.createElement(_components.p, null, "Native Performance Culture and the Rhythm of (Re) Conciliation: Remembering Ourselves in Deep Time, with Lee Maracle and the Digital Dramaturgy Lab_ squared. ", React.createElement(_components.em, null, "Streaming Life: Storying the 94"), ", ", React.createElement(_components.a, {
    href: "https://youtu.be/VxEpi0noKLU"
  }, "https://youtu.be/VxEpi0noKLU"), ". A site-specific truth activation on the St. George Campus, University of Toronto.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
